import React from 'react';

function Hero(props){
    const slides = [];

    props.data.contentText.forEach((slide)=> {
        slides.push(
            <section className="m8 animated fadeIn" key={slide.id}>
                <div className="m8_hero">
                    <div className="m8_hero_figure">
                        <div className="c7">
                            <figure className="c7_figure">
                                <img className="c7_figure_image" src={slide.image.file.url} title={slide.image.title} alt={slide.image.description}  />
                            </figure>
                        </div>
                    </div>
                    <div className="m8_content">
                        <div className="c2 -variant-3 animated fadeIn delay-1s">
                            <h1 className="c2_headline"><span className="c2_headline_small">{slide.subtitle}</span><br />{slide.title}</h1>
                            <p className="c2_content">{slide.contentText.contentText}</p>

                            <a href={slide.ctaLink} className="c2_cta animated pulse delay-2s">{slide.ctaText}</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    });

    return (slides);
}

export default Hero;
